import { TinyEmitter } from 'tiny-emitter'
import { IMessage } from '@smartsupp/smartsupp-message'

import store from 'store'
import { showWidget, toggleOpenCloseWidget, GeneralAction, handleApiFunction } from 'store/general/actions'
import { DEFAULT_LOCALE } from 'store/translations/constants'
import { MessageAction } from 'store/messages/actions'
import { ApiCalls, EventsSupported } from 'constants/apiConstants'
import { printWarning } from 'utils'

import {
	formatCustomVisitorVariables,
	updateCustomUserVariables,
	validateVisitorDataFormat,
	VariablesAction,
} from 'store/variables'
import { visitorClient } from './connect'
import { pubSub } from './pubSub'
import { getSsWidget } from './sdk'
import { removeFromStorage, setToStorage } from './cookie'
import { storageAnalyticsConsent, storageMarketingConsent, storageVisitsName } from '../constants/cookies'
import { initializeGA } from './googleAnalytics'
import { isConsentModeEnabled } from './consents'
import { setUpSmartlook } from './smartlook'
import { VisitorCustomVariables } from '../store/variables/types'

interface VisitorData {
	name: string
	group: string
	email: string
	variables: string
	language: string
	phone: string
}

export const emitter = new TinyEmitter()
export const updatedVisitorData: Partial<VisitorData> = {}

export const generateFileDownloadUrl = (name: string, url?: string | null) => {
	if (url) {
		return `${url}?name=${name}`
	}
	return undefined
}

const listenToEvent = (name: string, callback: unknown): void => {
	if (typeof callback !== 'function') {
		console.error(`${callback} is not a function.`)
		return
	}

	switch (name) {
		case EventsSupported.MessageSent:
			emitter.on(EventsSupported.MessageSent, (message: IMessage) => {
				callback(message)
			})
			break
		case EventsSupported.MessageReceived:
			emitter.on(EventsSupported.MessageReceived, (message: IMessage) => {
				callback(message)
			})
			break
		default:
			console.error('Unknown event.')
	}
}

export const mapApiCall = (name: ApiCalls, param: any, ...args: unknown[]): void => {
	const parentWindow = window.parent as any
	// TODO: Disabled, fixing would cause BC breaks
	// Free users do not have the right to use api
	// const { packageName } = getSsWidget().options
	// if (packageName === SmartsuppPackage.Free) {
	//	printWarning('API commands are not available in FREE package.')
	//	return
	// }

	if (typeof name === 'function') {
		store.dispatch(handleApiFunction(name))
		return
	}

	if (name === ApiCalls.RecordingDisable || name === ApiCalls.AnalyticsConsent || name === ApiCalls.MarketingConsent) {
		if (typeof param !== 'boolean') {
			printWarning('Invalid parameter. Must be true or false.')
			return
		}
	}

	switch (name) {
		case ApiCalls.SettingsGetTranslates:
			pubSub.subscribe(name, param)
			break
		case ApiCalls.Variables:
			if (validateVisitorDataFormat(param)) {
				handleCustomApiVariables(param)
			}
			break
		case ApiCalls.Name:
		case ApiCalls.Group:
		case ApiCalls.Email:
		case ApiCalls.Phone:
			updatedVisitorData[name] = param
			store.dispatch(GeneralAction.updateUser({ [name]: param }))
			store.dispatch(VariablesAction.setPendingVariables({ [name]: param }))
			try {
				visitorClient.client.update({ [name]: param })
			} catch (error) {
				// Visitor client is not connected yet
			}
			break
		case ApiCalls.Language:
			updatedVisitorData[name] = param || DEFAULT_LOCALE
			break
		case ApiCalls.ChatClose:
			store.dispatch(toggleOpenCloseWidget(false, false))
			break
		case ApiCalls.ChatOpen:
			store.dispatch(toggleOpenCloseWidget(true, true))
			store.dispatch(showWidget())
			break
		case ApiCalls.ChatShow:
			store.dispatch(showWidget())
			break
		case ApiCalls.ChatHide:
			store.dispatch(GeneralAction.widgetHide())
			break
		case ApiCalls.ThemeColor:
			if (getSsWidget().options.features.api) {
				store.dispatch(GeneralAction.setThemeColor(param))
			}
			break
		case ApiCalls.ChatMessage:
			store.dispatch(MessageAction.setInputText(param))
			break
		case ApiCalls.RecordingDisable:
			// Set up in loader => prevent throwing error
			break
		case ApiCalls.RecordingOff:
			// Set up in loader => prevent throwing error
			break
		case ApiCalls.On:
			listenToEvent(param, args[0])
			break
		// Special kind of request that should be ignored but not throw console.log
		case ApiCalls.HtmlApply:
			break

		// Consents
		case ApiCalls.AnalyticsConsent: {
			if (!isConsentModeEnabled()) {
				printWarning('Enable managing cookie consent in widget settings to allow this command.')
			}

			const hasConsent = Boolean(param)
			setToStorage({ name: storageAnalyticsConsent, value: String(hasConsent), excludeCookie: true })

			// Google Analytics
			if (!hasConsent) store.dispatch(GeneralAction.gaPaused())
			else store.dispatch(GeneralAction.gaResumed())
			initializeGA()

			// Smartlook
			if (parentWindow.smartlook) {
				if (hasConsent) parentWindow.smartlook('resume')
				else parentWindow.smartlook('pause')
			}
			setUpSmartlook()

			break
		}
		case ApiCalls.MarketingConsent: {
			if (!isConsentModeEnabled()) {
				printWarning('Enable managing cookie consent in widget settings to allow this command.')
			}

			const hasConsent = Boolean(param)
			setToStorage({ name: storageMarketingConsent, value: String(hasConsent), excludeCookie: true })
			if (!hasConsent) {
				removeFromStorage(storageVisitsName)
			}
			break
		}

		default:
			printWarning(`Unknown API command: ${name}`)
	}
}

export const handleCustomApiVariables = (param: VisitorCustomVariables) => {
	try {
		updatedVisitorData[ApiCalls.Variables] = formatCustomVisitorVariables(param)
		store.dispatch(GeneralAction.updateUserVariables(formatCustomVisitorVariables(param)))
		store.dispatch(updateCustomUserVariables(param))
	} catch (e) {
		printWarning(e)
	}
}
