import VisitorClient, {
	VisitorEvents,
	AccountStatus,
	SocketError,
	ChatStatus as ClientChatStatus,
	Agent,
} from '@smartsupp/websocket-client-visitor'

import store from 'store'
import {
	MessageAction,
	addAgent,
	removeAgent,
	removeAllAgents,
	addMessageFromServer,
	checkMessagesAndMarkAsRead,
	assignAgent,
	unassignAgent,
} from 'store/messages/actions'
import { configLang } from 'store/translations/constants'
import { GeneralAction, toggleOpenCloseWidget } from 'store/general/actions'
import { isWidgetOpen, isWidgetVisible, openWidgetOnTrigger } from 'store/general/selectors'
import { AgentActions, setAgentStatus, setAgentStatusWithNotification } from 'store/agent/actions'
import { ChatStatus } from 'model/Enums'
import { handleError } from 'utils/errorHelpers'
import { getSsWidget } from 'utils/sdk'
import { botMessageSentGALogger, conversationServedGA, messageReceivedGaLogger } from 'utils/googleAnalytics'
import { filterConnectedAgents } from 'utils/agentHelpers'
import { getServerUrl } from 'utils/serverHelper'
import { updatedVisitorData, emitter } from 'utils/apiHelper'
import { secretDebug } from 'utils/debug'
import { EventsSupported } from 'constants/apiConstants'
import { FAKE_BOT_TYPING, DISCONNECT_TIMER, GET_WIDGET_WIDTH_TIMER } from 'constants/timeoutConstants'
import { updateCustomUserVariables, VariablesAction } from 'store/variables'
import { getLastMessageId } from 'store/messages/selectors'
import { MessageSubType } from '@smartsupp/smartsupp-message'
import { WIDGET_VERSION } from 'constants/app'
import { getVisitsFromStorage, setVisitsToStorage } from './visits'
import { getVisitorIdFromStorage, setVisitorIdToStorage } from './visitor'
import { printWarning } from './printWarning'
import { closeCrossDomainStorage } from './crossDomainStorage'
import { connectQueue } from './connectQueue'

interface AppVisitorClient {
	client: VisitorClient
}

const visitorData = getSsWidget().options.visitorData || {}
let isReconnected = false

// eslint-disable-next-line func-names
export const visitorClient = (function () {
	const visitorClient = {} as AppVisitorClient // TODO: refactor

	// Option for Dash, to get translates
	if (!getSsWidget().options.host) {
		return visitorClient
	}

	setTimeout(async () => {
		let visitorId = null
		try {
			visitorId = await getVisitorIdFromStorage()
		} catch (e) {
			printWarning(e)
		}

		visitorClient.client = new VisitorClient({
			data: {
				_chatMaxReopenTime: getSsWidget().options._chatMaxReopenTime,
				bundleVersion: process.env.REACT_APP_VERSION || 'dev',
				domain: window.top.location.hostname || window.parent.location.hostname || 'unknown',
				email: updatedVisitorData.email || visitorData.email,
				group: updatedVisitorData.group || visitorData.group,
				id: visitorId,
				isPreviewMode: getSsWidget().options.isPreviewMode,
				isWidgetOpen: isWidgetOpen(store.getState()),
				isWidgetVisible: isWidgetVisible(store.getState()),
				key: getSsWidget().options.key,
				lang: updatedVisitorData.language || configLang.lang,
				name: updatedVisitorData.name || visitorData.name,
				pageTitle: window.top.document.title || window.parent.document.title,
				pageUrl: window.top.location.href || window.parent.location.href,
				phone: updatedVisitorData.phone || visitorData.phone,
				referer: window.top.document.referrer || window.parent.document.referrer,
				sitePlatform: getSsWidget().options.sitePlatform,
				triggerable: getSsWidget().options.triggerable,
				userAgent: window.top.navigator.userAgent || window.parent.navigator.userAgent,
				variables: updatedVisitorData.variables || visitorData.variables,
				visits: getVisitsFromStorage(),
				widgetVersion: WIDGET_VERSION,
			},
			connection: {
				url: `${getServerUrl()}`,
				balancerUrl: getSsWidget().options.balancerUrl,
				options: {
					path: '/socket',
					autoConnect: false,
					reconnection: true,
					reconnectionDelay: 1000,
					reconnectionDelayMax: 30000,
				},
			},
		})

		// eslint-disable-next-line
		// @ts-ignore
		if (getSsWidget().options.onClientCreated) {
			getSsWidget().options.onClientCreated(visitorClient.client)
		}

		// Agent events
		visitorClient.client.on('agent.status_updated', data => {
			store.dispatch(AgentActions.updateAgentStatus(data))
		})

		visitorClient.client.on('agent.updated', data => {
			store.dispatch(AgentActions.updateAgent(data))
		})

		visitorClient.client.on('agent.removed', data => {
			// Complete agent removal
			store.dispatch(AgentActions.deleteAgent(data.id))
		})

		// Account events
		visitorClient.client.on('account.updated', chatState => {
			store.dispatch(setAgentStatusWithNotification(chatState.status as AccountStatus))
			store.dispatch(GeneralAction.widgetOnline())
		})

		// Chat events
		visitorClient.client.on('chat.agent_assigned', data => {
			store.dispatch(assignAgent(data))
		})

		visitorClient.client.on('chat.agent_unassigned', (data: VisitorEvents.ChatAgentUnassigned) => {
			store.dispatch(unassignAgent(data))
		})

		visitorClient.client.on('chat.agent_typing', (isTyping: VisitorEvents.ChatAgentTyping) => {
			store.dispatch(AgentActions.setIsAgentTyping(isTyping.typing.is))
		})

		visitorClient.client.on('chat.agent_joined', data => {
			store.dispatch(addAgent(data))
			conversationServedGA(data.agent.fullname)
		})

		visitorClient.client.on('chat.agent_left', data => {
			store.dispatch(removeAgent(data))
		})

		visitorClient.client.on('chat.contact_read', data => {
			store.dispatch(MessageAction.setLastRead(data.lastReadAt))
			store.dispatch(GeneralAction.widgetOnline())
		})

		visitorClient.client.on('chat.message_received', ({ message }) => {
			const messages = [...store.getState().messages.messages]

			// avoid double rendering of message for client who sent the message
			// it will be rendered on chat.message event
			// no need to render it also on chat.message_received event
			if (messages.find(mess => mess.id === message.id)) return

			store.dispatch(AgentActions.setIsAgentTyping(false))

			if (message.subType !== MessageSubType.Contact) {
				store.dispatch(MessageAction.setIsFakeTyping(true))
			}

			setTimeout(() => {
				store.dispatch(
					addMessageFromServer({
						message,
						agents: store.getState().agent.agents,
					}),
				)
				store.dispatch(MessageAction.setIsFakeTyping(false))
			}, FAKE_BOT_TYPING)

			store.dispatch(checkMessagesAndMarkAsRead())

			if (!store.getState().general.isWidgetOnline) {
				store.dispatch(GeneralAction.widgetOnline())
			}

			if (message.subType === MessageSubType.Bot) {
				store.dispatch(GeneralAction.setChatStatus(ChatStatus.Pending))
				store.dispatch(MessageAction.setBotName(message.trigger?.name))
			}

			if (
				openWidgetOnTrigger(store.getState()) &&
				(message.subType === MessageSubType.Bot || message.subType === MessageSubType.Trigger)
			) {
				store.dispatch(toggleOpenCloseWidget(true))
			}

			store.dispatch(MessageAction.overrideDynamicWidgetOptions(message.widgetOptions))

			messageReceivedGaLogger(messages, message)
			botMessageSentGALogger(messages, message)

			emitter.emit(EventsSupported.MessageReceived, message)
		})

		visitorClient.client.on('chat.visitor_closed', () => {
			store.dispatch(GeneralAction.setChatStatus(ChatStatus.ClosedByVisitor))
		})

		visitorClient.client.on('chat.updated', data => {
			store.dispatch(GeneralAction.setChatStatus(ChatStatus.Served))
			if (data.changes?.widgetOptions) {
				store.dispatch(GeneralAction.updateDynamicWidgetOptions(data.changes.widgetOptions))
			}
		})

		visitorClient.client.on('chat.served', () => {
			store.dispatch(GeneralAction.setChatStatus(ChatStatus.Served))
		})

		visitorClient.client.on('chat.opened', () => {
			store.dispatch(GeneralAction.setChatStatus(ChatStatus.Opened))
		})

		visitorClient.client.on('chat.closed', (data: VisitorEvents.ChatClosed) => {
			if (data.message.content.data.closeType === 'agent_close') {
				store.dispatch(removeAllAgents(data))
				store.dispatch(GeneralAction.setChatStatus(ChatStatus.Resolved))
			}
		})

		visitorClient.client.on('chat.message_updated', ({ message }: VisitorEvents.ChatMessageUpdated) => {
			store.dispatch(MessageAction.hideChatbotMessageOptions(message.id))

			const lastMessageId = getLastMessageId(store.getState())
			if (message.widgetOptions && lastMessageId === message.id) {
				store.dispatch(MessageAction.overrideDynamicWidgetOptions(message.widgetOptions))
			}
		})

		visitorClient.client.on('visitor.updated', userData => {
			store.dispatch(GeneralAction.updateUser(userData))
		})

		visitorClient.client.on('reconnecting', () => {
			isReconnected = true
		})

		const refreshData = (data: VisitorClient.ConnectedData) => {
			const messages = data.chat?.messages
			if (isReconnected && messages?.length) {
				store.dispatch(AgentActions.setAgents(data.account.agents))
				store.dispatch(setAgentStatus(data.account.status))
				store.dispatch(MessageAction.setMessagesFromServer(messages))

				const storedAgents: Agent[] = store.getState().agent.agents
				const assignedIds = data.chat && data.chat.assignedIds
				const filteredAgents = filterConnectedAgents(assignedIds, storedAgents)
				store.dispatch(AgentActions.setConnectedAgents(filteredAgents))
			}
		}

		// General events
		visitorClient.client.on('initialized', data => {
			secretDebug('initialized in widget listened')
			store.dispatch(GeneralAction.setIsWidgetConnected(true))
			setTimeout(() => {
				store.dispatch(GeneralAction.widgetOnline())
				secretDebug('initialized timeout ran')
			}, DISCONNECT_TIMER)
			secretDebug('initialized listened and ran all the function inside itself')

			refreshData(data)

			if (data.chat?.widgetOptions) {
				store.dispatch(GeneralAction.updateDynamicWidgetOptions(data.chat.widgetOptions))
			}
		})

		visitorClient.client.on('connect', () => {
			secretDebug('connect listened')
			store.dispatch(GeneralAction.widgetOnline())
			store.dispatch(GeneralAction.setIsWidgetConnected(true))
		})

		visitorClient.client.on('disconnect', () => {
			setTimeout(() => store.dispatch(GeneralAction.widgetOffline()), DISCONNECT_TIMER)
			store.dispatch(GeneralAction.setIsWidgetConnected(false))
		})

		visitorClient.client.on('error', (err: Error | SocketError) => {
			handleError('error.serverError', err)
		})

		const getData = async () => {
			try {
				const data = await visitorClient.client.connect()
				store.dispatch(GeneralAction.setIsWidgetConnected(false))

				secretDebug('data', data)
				store.dispatch(GeneralAction.widgetOnline())

				// Save visitor ID
				const { id } = visitorClient.client.identity!
				try {
					await setVisitorIdToStorage(id)
					closeCrossDomainStorage()
				} catch (e) {
					printWarning(e)
				}
				setVisitsToStorage(data.visitor.visits)

				// Set vid in global smartsupp object
				const parentWindow = window.parent as any
				parentWindow.smartsupp.vid = id

				if (data) {
					store.dispatch(GeneralAction.setIsWidgetConnected(true))
					store.dispatch(VariablesAction.updateUserVariables(data.visitor.variables))
					store.dispatch(
						updateCustomUserVariables({ ...data.visitor.variables, ...store.getState().variables.pendingVariables }),
					)

					store.dispatch(GeneralAction.setUser(data))
					store.dispatch(setAgentStatus(data.account.status))
					store.dispatch(MessageAction.setAcceptedFileTypes(data.fileUpload.acceptedFileTypes))
					store.dispatch(MessageAction.setAcceptedFileExtensions(data.fileUpload.acceptedFileExtensions))
					store.dispatch(MessageAction.setAcceptedFileMaxSize(data.fileUpload.maxFileSize))

					if (data.chat) {
						store.dispatch(MessageAction.setLastRead(data.chat.unreadInfo.lastReadAt))
						if (data.chat.isClosed) {
							store.dispatch(GeneralAction.setChatStatus(ChatStatus.ClosedByVisitor))
						} else {
							switch (data.chat.status) {
								case ClientChatStatus.Closed: {
									store.dispatch(GeneralAction.setChatStatus(ChatStatus.Resolved))
									break
								}
								case ClientChatStatus.Open: {
									store.dispatch(GeneralAction.setChatStatus(ChatStatus.Opened))
									break
								}
								case ClientChatStatus.Served: {
									store.dispatch(GeneralAction.setChatStatus(ChatStatus.Served))
									break
								}
								case ClientChatStatus.Pending: {
									store.dispatch(GeneralAction.setChatStatus(ChatStatus.Pending))
									break
								}
								default:
									break
							}
						}
					}
				}

				const { agents } = data.account
				store.dispatch(AgentActions.setAgents(agents))

				// Serves for setting connected agents
				const assignedIds = data.chat && data.chat.assignedIds
				const filteredAgents = filterConnectedAgents(assignedIds, agents)
				store.dispatch(AgentActions.setConnectedAgents(filteredAgents))

				if (data.chat && data.chat.messages && data.chat.messages.length) {
					store.dispatch(
						addMessageFromServer({
							message: data.chat.messages,
							agents: store.getState().agent.agents,
						}),
					)
				}
				store.dispatch(AgentActions.setAgents(agents))
				store.dispatch(GeneralAction.widgetOnline())
				setTimeout(() => store.dispatch(GeneralAction.widgetLoadedToggle()), GET_WIDGET_WIDTH_TIMER)
				store.dispatch(checkMessagesAndMarkAsRead())

				// Execute all functions from connect queue
				connectQueue.executeAll()
			} catch (error) {
				store.dispatch(GeneralAction.widgetHide())
				handleError('error.serverError', error)
			}
		}

		getData()
	}, 0)

	return visitorClient
})()
