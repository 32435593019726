import { Action, action, payload } from 'ts-action'
import { Dispatch } from 'redux'
import { AppThunkAction } from '../../types'
import { handleUpdateCustomVisitorVariables, validateVisitorDataFormat } from './utils'
import { secretDebug } from '../../utils/debug'
import { State } from '../combinedReducers'
import { VisitorCustomVariables } from './types'

export const VariablesAction = {
	resetPendingVariables: action('variables/UPDATE_VARIABLES'),
	setPendingVariables: action('variables/SET_PENDING_VARIABLES', payload<VisitorCustomVariables>()),
	setIsClientConnecting: action('variables/SET_IS_CLIENT_CONNECTING', payload<boolean>()),
	updateUserVariables: action('variables/SET_USER_VARIABLES', payload<VisitorCustomVariables>()),
}

export type VariablesActionType = typeof VariablesAction

export const updateCustomUserVariables: AppThunkAction<VariablesActionType> =
	(payload: VisitorCustomVariables) =>
	(dispatch: Dispatch<Action>, getState: () => State): void => {
		if (!validateVisitorDataFormat(payload)) return

		const state = getState()
		if (!state.general.isWidgetConnected) {
			dispatch(VariablesAction.setPendingVariables(payload))
			return
		}

		try {
			handleUpdateCustomVisitorVariables(payload)
		} catch (error) {
			secretDebug(error)
		}
	}
