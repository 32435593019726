import { action, payload } from 'ts-action'
import { Dispatch, Action } from 'redux'
import {
	VisitorEvents,
	AccountStatus,
	WidgetOptions as DynamicWidgetOptions,
	Agent,
} from '@smartsupp/websocket-client-visitor'
import { Message, MessageContentType, MessageSubType, MessageType } from '@smartsupp/smartsupp-message'
import debounce from 'lodash/debounce'

import store from 'store'
import { State } from 'store/combinedReducers'
import { generalSelectors } from 'store/general'
import { GeneralAction } from 'store/general/actions'
import { AgentActions } from 'store/agent/actions'
import { agentsSelectors } from 'store/agent'
import { AuthenticationData } from 'store/messages/types'

import { promiseTimeout } from 'utils/promiseWithTimeout'
import { LoadingState, AgentRating, WarningBarState, FileUploadState, ChatStatus } from 'model/Enums'
import { RatingInput, RatingForm } from 'model/Rating'

import { emitter } from 'utils/apiHelper'
import { generateAuthFormInputs } from 'utils/authFormHelper'
import { visitorClient } from 'utils/connect'
import { handleError } from 'utils/errorHelpers'
import { getSsWidget } from 'utils/sdk'
import {
	getValuesFromForm,
	replaceEmojisInText,
	userSentSameMessageTwiceInRow,
	sortMessagesByDate,
	playSound,
} from 'utils/messageHelpers'
import { secretDebug } from 'utils/debug'

import { EventsSupported } from 'constants/apiConstants'
import { WARNING_BAR_TIMER } from 'constants/errorConstants'
import { AUTH_FORM_MESSAGE_SEND_DELAY } from 'constants/messageListConstants'
import { IS_TYPING_DEBOUNCE_TIME, SENDING_MESSAGE_MAX_TIMEOUT } from 'constants/formConstants'
import {
	authFormFilledGA,
	chatbotButtonInteractionGA,
	feedbackSentGA,
	offlineMessageSentGaLogger,
} from 'utils/googleAnalytics'

import { AppThunkAction } from 'types'
import { isAuthenticationDisabled } from 'store/general/selectors'
import { createRatingForm } from 'data-sets/ratingMessage'

export const MessageAction = {
	addRatingForm: action('messages/ADD_RATING_FORM', payload<RatingInput>()),
	removeRatingForm: action('messages/REMOVE_RATING_FORM', payload<{ messageId: string }>()),
	setRatingValue: action(
		'messages/SET_INPUT_VALUE',
		payload<{ messageId: string; rating?: AgentRating; ratingText?: string }>(),
	),
	setEmojiPickerState: action('messages/SET_EMOJI_PICKER_STATE', payload<boolean>()),
	setIsTyping: action('messages/SET_IS_TYPING', payload<boolean>()),
	setIsFakeTyping: action('messages/SET_IS_FAKE_TYPING', payload<boolean>()),
	updateRatingMessage: action(
		'messages/UPDATE_RATING_MESSAGE',
		payload<{
			messageId: string
			ratingForm: RatingForm
		}>(),
	),
	addMessageFromServer: action(
		'messages/ADD_MESSAGE_FROM_SERVER',
		payload<{
			message: Message | Message[]
			agents: Agent[]
		}>(),
	),
	setMessagesFromServer: action('messages/SET_MESSAGES_FROM_SERVER', payload<Message[]>()),
	submitRatingForm: action('messages/SUBMIT_RATING_FORM', payload<string>()),
	setFormLoadingState: action(
		'messages/SET_FORM_LOADING_STATE',
		payload<{ messageId: string; loadingState: LoadingState }>(),
	),
	setEnableSoundsState: action('general/SET_SOUNDS_ALLOWED_STATE', payload<boolean>()),
	finishTranscript: action('messages/FINISH_TRANSCRIPT', payload<string>()),
	markAllAsRead: action('messages/MARK_ALL_AS_READ'),
	addSendTranscript: action('messages/ADD_SEND_TRANSCRIPT'),
	addImageMessage: action(
		'messages/ADD_IMAGE_MESSAGE',
		payload<{
			url: string
			name: string
			type: string
			uploadedWithError: boolean
			isImage: boolean
			url400: string | undefined
		}>(),
	),
	hideChatbotMessageOptions: action('messages/HIDE_CHATBOT_MESSAGE_OPTIONS', payload<string>()),
	setInputText: action('messages/SET_INPUT_TEXT', payload<string>()),
	setWarningBarState: action('messages/SET_WARNING_BAR_STATE', payload<WarningBarState>()),
	setFileUploadState: action('messages/SET_FILE_UPLOAD_STATE', payload<FileUploadState>()),
	setLastRead: action('messages/SET_UNREAD_INFO', payload<string | null>()),
	setMessageSendingState: action('messages/SET_SENDING_STATE', payload<LoadingState>()),
	setAcceptedFileTypes: action('messages/SET_ACCEPTED_FILE_TYPES', payload<string[]>()),
	setAcceptedFileExtensions: action('messages/SET_ACCEPTED_FILE_EXTENSIONS', payload<string[]>()),
	setAcceptedFileMaxSize: action('messages/SET_ACCEPTED_FILE_MAX_SIZE', payload<number>()),
	createBotResponse: action('messages/CREATE_BOT_RESPONSE', payload<string>()),
	setBotName: action('messages/SET_BOT_NAME', payload<string | undefined>()),
	overrideDynamicWidgetOptions: action(
		'messages/OVERRIDE_DYNAMIC_WIDGET_OPTIONS',
		payload<Partial<DynamicWidgetOptions> | undefined>(),
	),
}

export const addAgent: AppThunkAction<MessageAction> =
	(agent: VisitorEvents.ChatAgentJoined) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const stateAgents = getState().agent.agents
		dispatch(AgentActions.addConnectedAgent(agent && agent.agent))
		dispatch(addMessageFromServer({ message: agent.message, agents: stateAgents }))
		setTimeout(() => dispatch(checkMessagesAndMarkAsRead()), 0)
	}

export const assignAgent: AppThunkAction<MessageAction> =
	(agent: VisitorEvents.ChatAgentAssigned) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(AgentActions.addConnectedAgentFromTransfer(agent))
		const stateAgents = getState().agent.agents
		dispatch(addMessageFromServer({ message: agent.message, agents: stateAgents }))
	}

// Is here separate because it kept loosing reference and during intensive typing is sent typing:false because debounce lost reference
// Tried multiple things including useCallback in ChatInput component, but the reference was still being lost
// IMPORTANT NOTE do not modify dispatch in this function as it would break the actions
// Solution was approved by stack overflow: https://stackoverflow.com/questions/50493683/debounce-method-inside-redux-thunk
const debounceStopTyping = debounce((dispatch: Dispatch<Action>) => {
	visitorClient.client.chatTyping(false)
	dispatch(MessageAction.setIsTyping(false))
}, IS_TYPING_DEBOUNCE_TIME)

export const setIsTyping: AppThunkAction<MessageAction> =
	() =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const { messages } = getState()
		const isUserTyping = messages.isTyping

		if (!isUserTyping) {
			dispatch(MessageAction.setIsTyping(true))
			visitorClient.client.chatTyping(true)
		}

		debounceStopTyping(dispatch)
	}

export const addSendTranscript: AppThunkAction<MessageAction> =
	() =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(MessageAction.addSendTranscript())
		setTimeout(() => dispatch(checkMessagesAndMarkAsRead()), 0)
	}

export const checkMessagesAndMarkAsRead: AppThunkAction<MessageAction> =
	(bypassClosedWidget?: boolean) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const { messages, general } = getState()
		const { lastReadAt } = messages
		const { isWidgetOpen } = general
		const mess = messages.messages
		const nonContactMessages = mess.filter(m => m.subType !== MessageSubType.Contact)
		const sortedMess = sortMessagesByDate(nonContactMessages)
		const lastReadSortedMess = sortedMess.length > 0 ? new Date(sortedMess[sortedMess.length - 1].createdAt) : undefined

		if (bypassClosedWidget) {
			dispatch(MessageAction.markAllAsRead())
		}

		if (isWidgetOpen) {
			const lastReadMessKnownByBE = lastReadAt ? new Date(lastReadAt) : lastReadSortedMess
			if (
				lastReadSortedMess &&
				lastReadMessKnownByBE &&
				lastReadSortedMess.getTime() >= lastReadMessKnownByBE.getTime() &&
				!document.hidden
			) {
				dispatch(MessageAction.markAllAsRead())
			}
		}
	}

export const setRating: AppThunkAction<MessageAction> =
	(messageId: string, rating: RatingInput) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const selectedMessage = getState().messages.messages.find(
			m => m.id === messageId && m.type === MessageType.Message && m.content.type === MessageContentType.RateForm,
		) as Message

		if (!selectedMessage) {
			console.error("'MessageAction.submitForm' called with wrong message ID, please check the code that called this")
			return
		}
		const ratingForm = getState().messages.ratingForms[messageId] || createRatingForm(rating.type)

		dispatch(
			MessageAction.updateRatingMessage({
				messageId,
				ratingForm,
			}),
		)

		await sendRating(selectedMessage, ratingForm, dispatch)

		feedbackSentGA(rating.type)
	}

export const removeAgent: AppThunkAction<MessageAction> =
	(
		agent: VisitorEvents.ChatAgentJoined | VisitorEvents.ChatAgentLeft | VisitorEvents.ChatClosed,
		calledFromTransfer = false,
	) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		if (getState().agent.connectedAgents.length <= 1 && calledFromTransfer === false) {
			if (getSsWidget().options?.ratingEnabled && generalSelectors.chatStatus(getState()) === ChatStatus.Served) {
				dispatch(addRating())
			}
		}
		const stateAgents = getState().agent.agents
		dispatch(addMessageFromServer({ message: agent.message, agents: stateAgents }))

		const { agentId } = agent.message.content.data
		if (agentId) {
			dispatch(AgentActions.removeConnectedAgent(agentId))
		}
	}

export const unassignAgent: AppThunkAction<MessageAction> =
	(agent: VisitorEvents.ChatAgentUnassigned) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const stateAgents = getState().agent.agents
		dispatch(addMessageFromServer({ message: agent.message, agents: stateAgents }))
		dispatch(AgentActions.removeConnectedAgentFromTransfer(agent))
	}

export const removeAllAgents: AppThunkAction<MessageAction> =
	(agent: VisitorEvents.ChatClosed) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		if (getSsWidget().options?.ratingEnabled && generalSelectors.chatStatus(getState()) === ChatStatus.Served) {
			dispatch(addRating())
			dispatch(GeneralAction.setChatStatus(ChatStatus.Resolved))
		}
		const stateAgents = getState().agent.agents
		dispatch(addMessageFromServer({ message: agent.message, agents: stateAgents }))
		dispatch(AgentActions.removeAllConnectedAgents())
	}

export const addRating: AppThunkAction<MessageAction> =
	() =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		try {
			const rateInit = await visitorClient.client.chatRateInit()
			const stateAgents = getState().agent.agents
			dispatch(addMessageFromServer({ message: rateInit.message, agents: stateAgents }))
		} catch (error) {
			console.error(error)
		}
	}

export const createBotResponse: AppThunkAction<MessageAction> =
	(text: string, payload: Record<string, unknown>, messageId: string) => async (): Promise<void> => {
		const replacedEmojisText = replaceEmojisInText(text)

		if (!payload.isGoBackButton) {
			chatbotButtonInteractionGA(text)
		}

		const sendMessage = () =>
			new Promise((resolve, reject) => {
				resolve(
					visitorClient.client.chatMessage({
						content: {
							type: MessageContentType.Text,
							text: replacedEmojisText,
						},
						quickReply: { replyTo: messageId, payload },
					}),
				)
			})

		promiseTimeout(SENDING_MESSAGE_MAX_TIMEOUT, sendMessage())
	}

export const createMessage: AppThunkAction<MessageAction> =
	(text: string, doAuthFormCheck = true) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const messageState = getState().messages
		const generalState = getState().general
		const { messages } = messageState
		const agentState = getState().agent
		const { status } = agentState

		dispatch(MessageAction.setMessageSendingState(LoadingState.Loading))

		if (!isAuthenticationDisabled(getState())) {
			const isUserAuthenticated = generalSelectors.isUserAuthenticated(getState())
			// todo vyladit , mozna dat do let generatedInputs

			const generatedInputs = generateAuthFormInputs(
				generalState.userData?.account.groups,
				generalState.userData?.visitor,
			)
			dispatch(GeneralAction.createAuthFormInputs(generatedInputs))

			if (
				(doAuthFormCheck && status === AccountStatus.Offline && !isUserAuthenticated && generatedInputs.length > 0) ||
				(getSsWidget().options.requireLogin && !isUserAuthenticated && generatedInputs.length > 0)
			) {
				dispatch(GeneralAction.authFormStateToggle())
				dispatch(MessageAction.setMessageSendingState(LoadingState.Initial))
				return
			}
		}

		// Forbid sending the same message two times in a row
		if (userSentSameMessageTwiceInRow(messages, text)) {
			dispatch(setWarningBar(WarningBarState.SameMessageTwice))
			dispatch(MessageAction.setMessageSendingState(LoadingState.Initial))
			return
		}

		const replacedEmojisText = replaceEmojisInText(text)
		const isWidgetOffline = agentsSelectors.getWidgetStatus(getState()) === AccountStatus.Offline

		try {
			dispatch(MessageAction.setInputText(''))

			const sendMessage = () =>
				new Promise((resolve, reject) => {
					resolve(
						visitorClient.client.chatMessage({
							isOffline: isWidgetOffline,
							content: {
								type: MessageContentType.Text,
								text: replacedEmojisText,
							},
						}),
					)
				})

			// Get the message from server
			const getMessage = promiseTimeout(SENDING_MESSAGE_MAX_TIMEOUT, sendMessage())

			// Wait for the promise to get resolved
			getMessage.then(response => {
				dispatch(
					addMessageFromServer({
						message: response,
						agents: agentState.agents,
					}),
				)
				emitter.emit(EventsSupported.MessageSent, response)
			})

			// Wait for the promise to get rejected or timed out
			getMessage.catch(error => {
				dispatch(MessageAction.setMessageSendingState(LoadingState.Initial))
				handleError('error.sendingMessageTooLong', error)
			})

			offlineMessageSentGaLogger(status)
			dispatch(MessageAction.setMessageSendingState(LoadingState.Success))
		} catch (err) {
			dispatch(MessageAction.setMessageSendingState(LoadingState.Failure))
			handleError('error.sendingMessage', err)
		}
	}

const sendRating = async (message: Message, ratingForm: RatingForm, dispatch: Dispatch<Action>) => {
	dispatch(
		MessageAction.setFormLoadingState({
			messageId: message.id,
			loadingState: LoadingState.Loading,
		}),
	)
	try {
		await visitorClient.client.chatRate({
			messageId: message.id,
			text: ratingForm.ratingText,
			value: ratingForm.rating,
		})
		dispatch(MessageAction.submitRatingForm(message.id))
		dispatch(
			MessageAction.setFormLoadingState({
				messageId: message.id,
				loadingState: LoadingState.Success,
			}),
		)
		setTimeout(() => dispatch(checkMessagesAndMarkAsRead()), 0)
	} catch (err) {
		handleError('error.sendingRating', err)
		dispatch(
			MessageAction.setFormLoadingState({
				messageId: message.id,
				loadingState: LoadingState.Failure,
			}),
		)
	}
}

const sendTranscript = async (email: string, lang: string, callback: () => void) => {
	try {
		await visitorClient.client.chatTranscript(email, lang)
		callback()
	} catch (err) {
		handleError('error.sendingTranscript', err)
	}
}

export const uploadFile: AppThunkAction<MessageAction> =
	(file: File) =>
	async (dispatch: Dispatch<Action>): Promise<void> => {
		try {
			dispatch(MessageAction.setFileUploadState(FileUploadState.Loading))
			const data = await visitorClient.client.chatUploadInit()

			const formData = new FormData()
			formData.append('file', file, file.name)

			const uploadResponse = await fetch(data.url, {
				method: 'post',
				body: formData,
			})

			if (!uploadResponse.ok) {
				throw new Error('Upload failed')
			}

			await visitorClient.client.chatUploadFinish(data.token)
		} catch (err) {
			dispatch(setWarningBar(WarningBarState.Failure))
		} finally {
			dispatch(MessageAction.setFileUploadState(FileUploadState.Initial))
		}
	}

export const submitRatingForm: AppThunkAction<MessageAction> =
	(messageId: string) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const selectedMessage = getState().messages.messages.find(
			m => m.id === messageId && m.type === MessageType.Message && m.content.type === MessageContentType.RateForm,
		) as Message | undefined

		if (!selectedMessage) {
			console.error("'MessageAction.submitForm' called with wrong message ID, please check the code that called this")
			return
		}

		const ratingForm = getState().messages.ratingForms[messageId]
		if (!ratingForm || !ratingForm.rating) {
			console.error("'MessageAction.submitForm' has no rating")
			return
		}

		dispatch(
			MessageAction.updateRatingMessage({
				messageId,
				ratingForm,
			}),
		)
		sendRating(selectedMessage, ratingForm, dispatch)

		if (ratingForm.rating) {
			feedbackSentGA(ratingForm.rating)
		}
	}

export const submitAuthForm: AppThunkAction<MessageAction> =
	(message: string) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		const { general } = getState()
		const filledInputs = getValuesFromForm(general.authFormInputs)
		const { userData } = general
		const visitor = userData?.visitor
		const { privacyNoticeCheckRequired } = getSsWidget().options

		const userEmailFromVisitorApi = visitor?.email
		const userNameFromVisitorApi = visitor?.name
		const userGroupFromVisitorApi = visitor?.group
		const userPhoneFromVisitorApi = visitor?.phone

		// TODO: fix types
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const authenticationData: AuthenticationData = {
			...((Boolean(userEmailFromVisitorApi) || Boolean(filledInputs.email)) && {
				email: userEmailFromVisitorApi || filledInputs.email,
			}),
			...((Boolean(userNameFromVisitorApi) || Boolean(filledInputs.name)) && {
				name: userNameFromVisitorApi || filledInputs.name,
			}),
			...((Boolean(filledInputs.group) || Boolean(userGroupFromVisitorApi)) && {
				group: filledInputs.group || userGroupFromVisitorApi,
			}),
			...((Boolean(userPhoneFromVisitorApi) || Boolean(filledInputs.phone)) && {
				phone: userPhoneFromVisitorApi || filledInputs.phone,
			}),
			...(Boolean(filledInputs.personalDataProcessingConsent) &&
				privacyNoticeCheckRequired && {
					personalDataProcessingConsent: filledInputs.personalDataProcessingConsent,
				}),
		}

		try {
			secretDebug('visitorClient.client.authenticate', visitorClient.client.authenticate, authenticationData)
			visitorClient.client.authenticate(authenticationData).then(() => {
				// After the request is send, only then send this message,
				//  because if the message came as first to BE, the choice of group would no be possible
				setTimeout(() => dispatch(createMessage(message, false)), AUTH_FORM_MESSAGE_SEND_DELAY)
				dispatch(GeneralAction.authFormStateToggle())
			})
			authFormFilledGA()
		} catch (e) {
			console.error('authentication fail', e)
			// TODO: Log error
		}
	}

export const submitTranscriptionForm: AppThunkAction<MessageAction> =
	(email: string) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		sendTranscript(email, getSsWidget().options.lang, () => {
			dispatch(MessageAction.finishTranscript(email))
			setTimeout(() => dispatch(checkMessagesAndMarkAsRead()), 0)
		})
	}

export const addMessageFromServer: AppThunkAction<MessageAction> =
	(data: { message: Message | Message[]; agents: Agent[] }) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(MessageAction.addMessageFromServer(data))
		const { message } = data
		if (generalSelectors.shouldPlaySound(getState()) && !Array.isArray(message)) {
			playSound(message.subType, message.type)
		}
	}

// Helper for showing warning bar
let timer: ReturnType<typeof setTimeout>
export const setWarningBar: AppThunkAction<MessageAction> =
	(state: WarningBarState) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		clearTimeout(timer)
		dispatch(MessageAction.setWarningBarState(state))
		timer = setTimeout(() => dispatch(MessageAction.setWarningBarState(WarningBarState.Initial)), WARNING_BAR_TIMER)
	}

document.addEventListener('visibilitychange', () => {
	const { isWidgetOnline } = store.getState().general

	const { isWidgetOpen } = store.getState().general
	const { messages } = store.getState().messages

	const messagesSent = messages.length > 0

	if (isWidgetOpen && !document.hidden && messagesSent) {
		visitorClient.client.chatRead()
	}

	secretDebug('event listener visibilty', 'isWidgetOnline', isWidgetOnline)

	if (!isWidgetOnline && !document.hidden) {
		secretDebug('reconnecting...')
		// TODO attemps limiter
		visitorClient.client.connect()
	}
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageAction = typeof MessageAction
