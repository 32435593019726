import store from '../index'
import { visitorClient } from '../../utils/connect'
import { ApiCalls } from '../../constants/apiConstants'
import { VariablesAction } from './actions'
import { VisitorCustomVariables } from './types'
import { printWarning } from '../../utils'

export const isObject = (obj: unknown) => typeof obj === 'object' && obj !== null

export const handleUpdateCustomVisitorVariables = (updatedVisitorVariables: VisitorCustomVariables) => {
	const state = store.getState()
	const { userVariables } = state.variables

	const currentVisitorVariables = { ...userVariables }
	let updatedData: VisitorCustomVariables = {}

	// Only update variables, that actually changed from values on server to allow for less requests
	Object.keys(updatedVisitorVariables).forEach(key => {
		if (currentVisitorVariables[key] !== updatedVisitorVariables[key]) {
			const formattedVisitorVariables = formatCustomVisitorVariables(updatedVisitorVariables)

			if (typeof formattedVisitorVariables === 'object') {
				updatedData = { ...updatedData, ...formattedVisitorVariables }
			} else {
				updatedData = { ...updatedData, formattedVisitorVariables }
			}
		}
	})

	if (Object.keys(updatedData).length) {
		visitorClient.client.update({ [ApiCalls.Variables]: updatedData })
	}
	store.dispatch(VariablesAction.resetPendingVariables())
	store.dispatch(VariablesAction.updateUserVariables(updatedData))
}

export const formatCustomVisitorVariables = (param: any) => {
	const variables: any = {}

	Object.keys(param).forEach(key => {
		variables[key] = isObject(param[key]) ? param[key].value : param[key]
	})

	return variables
}

export const validateVisitorDataFormat = (data: unknown): boolean => {
	if (!isObject(data)) {
		printWarning(
			'Wrong visitor data format. Please see: https://docs.smartsupp.com/chat-box/visitor-identification/ for more information.',
		)
		return false
	}
	return true
}
