import React, { useEffect, Suspense, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import { WidgetButtonWrapper } from 'components/widgetButton/WidgetButtonWrapper'
import { registerTimeAgoLocale } from 'utils/timeAgoWrapper'
import { ButtonStyle } from 'model/ChatPosition'
import * as translationSelectors from 'store/translations/selectors'
import { generalSelectors } from 'store/general'
import { setFrameSize } from 'utils/iframeSizeHelper'
import { initializeGA } from 'utils/googleAnalytics'
import { renderGlobalCssSettings } from 'styles/shared'
import ErrorBoundary from 'ErrorBoundary'
import { messageSelectors } from 'store/messages'
import ChatWrapper from 'components/chat/ChatWrapper'
import { setUpSmartlook } from 'utils/smartlook'

const Chat = React.lazy(() => import(/* webpackChunkName: "chat" */ 'components/chat/Chat'))
const Trigger = React.lazy(() => import(/* webpackChunkName: "trigger" */ 'components/trigger/Trigger'))

const parentWindow = window.parent as any
// add property with the widget version to the global _smartsupp object
// can be read by `console.log(_smartsupp.widgetVersion)`
const _smartsupp = parentWindow._smartsupp || {}
_smartsupp.widgetVersion = process.env.REACT_APP_VERSION || ''
parentWindow._smartsupp = _smartsupp

initializeGA()
registerTimeAgoLocale()
setUpSmartlook()

const App = () => {
	const buttonStyle = useSelector(generalSelectors.isBubbleOrGreeting)
	const theme = useSelector(generalSelectors.theme)
	const isWidgetVisible = useSelector(generalSelectors.isWidgetVisible)
	const isWidgetMobile = useSelector(generalSelectors.isWidgetMobile)
	const isWidgetOpen = useSelector(generalSelectors.isWidgetOpen)
	const isTranslationFetching = useSelector(translationSelectors.isTranslationFetching)
	const showTrigger = useSelector(generalSelectors.showTrigger)
	const showTyping = useSelector(messageSelectors.showTriggerTypingAnimation)

	const triggerRef = useRef<HTMLDivElement>(null)
	const widgetButtonRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setFrameSize({
			isWidgetOpen,
			isWidgetMobile,
			showTrigger,
			isWidgetVisible,
			widerVersion: buttonStyle === ButtonStyle.Greeting,
			actualWidthOfWidget: widgetButtonRef.current?.clientWidth,
			actualHeightOfWidget: triggerRef.current?.clientHeight,
		})
	}, [isWidgetOpen, isWidgetMobile, showTrigger, buttonStyle, isWidgetVisible, showTyping])

	return (
		<ThemeProvider theme={theme}>
			<ErrorBoundary>
				<Global styles={renderGlobalCssSettings} />
				{isWidgetVisible && !isTranslationFetching && (
					<ChatWrapper>
						{showTrigger && (
							<Suspense fallback={<div> &nbsp; </div>}>
								<Trigger reference={triggerRef} />
							</Suspense>
						)}
						{isWidgetOpen ? (
							<Suspense fallback={<div> &nbsp; </div>}>
								<Chat />
							</Suspense>
						) : (
							<WidgetButtonWrapper widgetButtonRef={widgetButtonRef} />
						)}
					</ChatWrapper>
				)}
			</ErrorBoundary>
		</ThemeProvider>
	)
}

export default App
