import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { generalSelectors } from 'store/general'
import { widgetOpenMaxHeight, widgetOpenMaxWidth } from 'constants/widgetSizeConstants'
import { widgetContainerPadding } from 'utils/paddingHelpers'

const ChatWrapper = ({ children }: { children: ReactNode }) => {
	const isWidgetMobile = useSelector(generalSelectors.isWidgetMobile)
	const isWidgetOpen = useSelector(generalSelectors.isWidgetOpen)

	return (
		<Box
			position="relative"
			overflow="hidden"
			height={isWidgetMobile ? '100%' : widgetOpenMaxHeight}
			width={isWidgetMobile ? '100%' : widgetOpenMaxWidth}
			maxHeight="100%"
			display="flex"
			flexDirection="column"
			boxSizing="border-box"
			padding={widgetContainerPadding(isWidgetMobile, isWidgetOpen)}
		>
			{children}
		</Box>
	)
}
export default ChatWrapper
