import store from 'store'
import { updateCustomUserVariables } from 'store/variables'
import { getAnalyticsConsent, getSsWidget } from 'utils'
import { secretDebug } from 'utils/debug'

const _window = window.parent as any

export const setUpSmartlook = () => {
	const { options } = getSsWidget()
	const hasAnalyticsConsent = getAnalyticsConsent()

	if (!hasAnalyticsConsent) {
		return
	}

	if (!options.smartlook || !options.smartlook.enabled) return
	if (_window.smartlook) return

	// if smartlook is already in parent (users web) -> do not initialize smartlook because we would override their setup
	if (options.recordingOff) return

	initSmartlook()
}

const initSmartlook = () => {
	const { options } = getSsWidget()

	// eslint-disable-next-line no-multi-assign, func-names
	const smartlook: any = (_window.smartlook = function (...args: any[]) {
		_window.smartlook.api.push(args)
	})
	_window.smartlook.api = []

	const head = _window.document.getElementsByTagName('head')[0]
	const script = _window.document.createElement('script')
	script.async = true
	script.type = 'text/javascript'
	script.charset = 'utf-8'
	script.crossOrigin = 'anonymous'
	script.src = options.smartlook.scriptUrl
	head.appendChild(script)

	smartlook('init', options.smartlook.key, {
		host: options.smartlook.serverHost,
	})
	smartlook('record', {
		forms: true,
		numbers: true,
		emails: true,
		ips: false,
	})

	if (options.recordingDisable) {
		smartlook('pause')
	} else {
		smartlook(() => updateUserVariables(smartlook.visitorId))
	}

	secretDebug('Smartlook initialized')
}

const updateUserVariables = (visitorId: string) => {
	store.dispatch(updateCustomUserVariables({ smartlook_vid: visitorId }))
}
