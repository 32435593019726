import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunk from 'redux-thunk'

import { reducer, State } from './combinedReducers'

let composeEnhancers = compose
if (process.env.NODE_ENV === 'development') {
	composeEnhancers = (window as any).top.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}
const store: Store = createStore<State, any, any, any>(reducer, composeEnhancers(applyMiddleware(thunk)))

export default store
