import { Message } from '@smartsupp/smartsupp-message'
import VisitorClient from '@smartsupp/websocket-client-visitor'
import { TranscriptForm } from 'model/Transcript'

export type AuthenticationData = VisitorClient.AuthenticateValues

/** Combination os **Message** (@smartsupp/smartsupp-message) and custom **Widget messages** */
export type FeedMessage = Message | WidgetMessage
/** Custom **Widget messages** which are shown in the message feed but are not sent to the Back end */
export type WidgetMessage = WidgetMessage.Transcript

export enum WidgetMessageSubType {
	Transcript = 'transcript',
}

/* eslint-disable @typescript-eslint/no-namespace,@typescript-eslint/no-redeclare */
export namespace WidgetMessage {
	export interface Base {
		id: string
		createdAt: Date
		type: 'widget_message'
		subType: WidgetMessageSubType
	}

	export interface Transcript extends Base {
		subType: WidgetMessageSubType.Transcript
		content: {
			form: TranscriptForm
		}
	}
}
