import { createSelector } from 'reselect'
import { AccountStatus, Agent, AgentStatus } from '@smartsupp/websocket-client-visitor'
import { State } from '../combinedReducers'
import { getSsWidget } from '../../utils/sdk'
import { filteredCorrectGroupAgents } from '../../utils/agentHelpers'
import { userData } from '../general/selectors'

const visitorData = getSsWidget().options.visitorData || {}

export const getAgents = (state: State) => state.agent.agents
const getConnectedAgents = (state: State) => state.agent.connectedAgents
const getGlobalStatus = (state: State) => state.agent.status

const getJoinedAgents = createSelector(getConnectedAgents, ag => ag.filter(a => !a.disabled))

export const getReversedJoinedAgents = createSelector(getJoinedAgents, (items): Agent[] => [...items].reverse())

export const getFilteredAgents = createSelector(getAgents, userData, (a, userInfo): Agent[] => {
	const nonDisabledAgents = a.filter(ag => !ag.disabled)

	return filteredCorrectGroupAgents(
		nonDisabledAgents,
		(visitorData && visitorData.group) || (userInfo && userInfo.visitor && userInfo.visitor.group),
	)
})

export const getWidgetStatus = createSelector(
	getJoinedAgents,
	getGlobalStatus,
	(joinedAgents, globalStatus): AccountStatus => {
		const oneOfAgentsIsOnlineHelper = (ag: Agent[]) =>
			ag.some(a => a.status === AgentStatus.Online) ? AccountStatus.Online : AccountStatus.Offline

		const oneOfJoinedAgentsIsOnline = oneOfAgentsIsOnlineHelper(joinedAgents)

		return joinedAgents.length > 0 ? oneOfJoinedAgentsIsOnline : globalStatus
	},
)
