import { v4 } from 'uuid'
import { ChannelType, Message, MessageContentType, MessageSubType, MessageType } from '@smartsupp/smartsupp-message'
import { AgentRating, LoadingState } from 'model/Enums'
import { TranslationService as T } from '../utils/TranslationService'
import { RatingInput, RatingForm } from '../model/Rating'

export const createRatingMessage = (
	rating: RatingInput,
	created = new Date(),
	messageId = v4(),
	value?: AgentRating,
): Message => ({
	id: messageId,
	type: MessageType.Message,
	subType: MessageSubType.System,
	createdAt: created.toISOString(),
	content: {
		type: MessageContentType.RateForm,
		data: {
			value: value || -1,
			text: T.translate(rating.formText),
		},
	},
	channel: {
		id: null,
		type: ChannelType.Default,
	},
	chatId: '',
	groupId: null,
	visitorId: '',
	agentId: null,
	trigger: null,
	attachments: [],
})

export function createRatingForm(rating?: AgentRating, ratingText?: string): RatingForm {
	return {
		loadingState: LoadingState.Initial,
		rating,
		finished: false,
		ratingText,
	}
}
