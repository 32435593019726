import css from '@emotion/css'
import { SSPX } from '../constants/themeConstants'

export const messageBaseWidth = css`
	width: 70%;
	max-width: 300px;
`

export const messagePadding = css`
	padding: 11px 14px;
	min-height: ${3 * SSPX}px;
`

export const messageGroupStandAloneCss = css`
	margin-top: ${1.5 * SSPX}px;
	margin-bottom: 0;
	border-radius: 10px;
`

export const messageGroupTopCss = css`
	margin-top: ${SSPX}px;
	margin-bottom: 0;
`

export const messageGroupMiddleCss = css`
	margin-top: 2px;
	margin-bottom: 0;
`

export const messageGroupBottomCss = css`
	margin-top: 2px;
	margin-bottom: 0;
`
