import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { ChatOrientation } from 'model/ChatPosition'
import { generalSelectors } from 'store/general'
import { TranslationService as T } from 'utils/TranslationService'
import { recalculateFrameWidth } from 'utils/iframeSizeHelper'
import { chatPosition } from 'constants/apiConstants'
import { SSPX } from 'constants/themeConstants'

import WidgetBubble from './WidgetBubble'

const WIDGET_BUTTON_MOBILE_FONT_SIZE = '14px'
const WIDGET_BUTTON_FONT_SIZE = '16px'

interface Props {
	widgetButtonRef: React.RefObject<HTMLDivElement>
}

const WidgetButton = ({ widgetButtonRef }: Props) => {
	const isWidgetMobile = useSelector(generalSelectors.isWidgetMobile)

	useEffect(() => {
		recalculateFrameWidth(widgetButtonRef.current?.clientWidth)
	}, [widgetButtonRef])

	return (
		<Box display="flex" flexDirection="row" className="wrapper" borderRadius={32} boxShadow={3}>
			{chatPosition === ChatOrientation.Left && <WidgetBubble />}
			<Box
				display="flex"
				alignItems="center"
				width="auto"
				padding="4px 0"
				overflow="hidden"
				data-testid="widgetButtonText"
			>
				<div
					ref={widgetButtonRef}
					style={{
						paddingLeft: chatPosition === ChatOrientation.Right ? 2 * SSPX : 0,
						paddingRight: chatPosition === ChatOrientation.Left ? 2 * SSPX : 0,
					}}
				>
					<Box
						fontSize={isWidgetMobile ? WIDGET_BUTTON_MOBILE_FONT_SIZE : WIDGET_BUTTON_FONT_SIZE}
						letterSpacing="-0.2px"
						maxWidth={150}
						overflow="hidden"
						whiteSpace="pre"
					>
						{T.translate('button.greeting')}
					</Box>
				</div>
			</Box>
			{chatPosition === ChatOrientation.Right && <WidgetBubble />}
		</Box>
	)
}

export default WidgetButton
