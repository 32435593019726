import { storageAnalyticsConsent, storageMarketingConsent } from 'constants/cookies'
import { getSsWidget } from './sdk'
import { getFromStorage } from './cookie'

export const isConsentModeEnabled = (): boolean => {
	const widgetOptions = getSsWidget().options
	return !!widgetOptions.consentModeEnabled
}

export const getAnalyticsConsent = (): boolean => {
	const analyticsConsent = getFromStorage(storageAnalyticsConsent) === 'true'
	return !(isConsentModeEnabled() && !analyticsConsent)
}

export const getMarketingConsent = (): boolean => {
	const marketingConsent = getFromStorage(storageMarketingConsent) === 'true'
	return !(isConsentModeEnabled() && !marketingConsent)
}
