import { Agent } from '@smartsupp/websocket-client-visitor'

export const getAgentFromArray = (agents: Agent[], agentId: string | null) => agents.find(a => a.id === agentId)

export const filteredCorrectGroupAgents = (agents: Agent[], groupFromSite: string | undefined | null): Agent[] => {
	if (groupFromSite) {
		if (groupFromSite === 'default') {
			return agents
		}

		return agents.filter(
			a => (a.groups && a.groups.length > 0 && a.groups.includes(groupFromSite)) || (a.groups && a.groups.length === 0),
		)
	}
	return agents
}

export const filterConnectedAgents = (assignedIds: string[] | undefined | null, agents: Agent[]) => {
	const connectedAgents: Agent[] = []

	if (assignedIds && assignedIds.length) {
		assignedIds.map(element => {
			const foundAgent = agents.find(a => a.id === element)
			return foundAgent && connectedAgents.push(foundAgent)
		})
		return connectedAgents
	}
	return []
}
