import { getSsWidget, updatedVisitorData } from 'utils'
import { WidgetTranslationsClient } from '@smartsupp/translations-client-api'
import { FetchAdapter } from '@openapi-client/adapter-fetch'
import { configLang } from './constants'

export const getVisitorLanguage = (): string =>
	updatedVisitorData.language ? updatedVisitorData.language : configLang.lang

export const getTranslationsClient = () =>
	new WidgetTranslationsClient(
		new FetchAdapter({
			baseURL: `${getSsWidget().options.translationsBaseUrl}/api/v1`,
		}),
	)
