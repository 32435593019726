import { reducer } from 'ts-action'
import { on } from 'ts-action-immer'
import { v4 } from 'uuid'
import { WidgetOptions as DynamicWidgetOptions } from '@smartsupp/websocket-client-visitor'

import { MessageAction } from 'store/messages/actions'
import { WarningBarState, LoadingState, FileUploadState } from 'model/Enums'
import { visitorClient } from 'utils/connect'
import { getInitSoundsState } from 'utils/localStorageHelper'
import { getFromStorage } from 'utils/cookie'
import { TranslationService as T } from 'utils/TranslationService'
import { createRatingForm, createRatingMessage } from 'data-sets/ratingMessage'
import { storageTextName } from 'constants/cookies'
import { enableSoundsStorageName } from 'constants/localStorage'
import {
	Attachment,
	AttachmentType,
	ChannelType,
	Message,
	MessageContentType,
	MessageSubType,
	MessageType,
} from '@smartsupp/smartsupp-message'
import { RatingForms } from 'model/Rating'
import cloneDeep from 'clone-deep'
import { FeedMessage, WidgetMessage, WidgetMessageSubType } from './types'

export const initialState = {
	currentTriggerMessageId: undefined as string | undefined,
	messages: [] as FeedMessage[],
	enableSounds: getInitSoundsState(),
	emojiPickerState: false,
	inputText: getFromStorage(storageTextName) || '',
	isTyping: false,
	fakeTyping: false,
	warningBarState: WarningBarState.Initial,
	fileUploadState: FileUploadState.Initial,
	lastReadAt: null as string | null,
	messageSendingState: LoadingState.Initial as LoadingState,
	acceptedFileTypes: [] as string[],
	acceptedFileExtensions: [] as string[],
	acceptedFileMaxSize: 0,
	currentBotName: undefined as string | undefined,
	overridingDynamicWidgetOptions: {} as Partial<DynamicWidgetOptions>,
	ratingForms: {} as RatingForms,
}

export type MessagesState = typeof initialState

export const messagesReducer = reducer<MessagesState>(
	initialState,
	on(MessageAction.setEnableSoundsState, (state: MessagesState, _) => {
		localStorage.setItem(enableSoundsStorageName, `${!state.enableSounds}`)
		state.enableSounds = !state.enableSounds
	}),
	on(MessageAction.addSendTranscript, (state: MessagesState, _) => {
		// push new widget message to messages feed (will be rendered as a form)
		state.messages.push({
			id: v4(),
			type: 'widget_message',
			subType: WidgetMessageSubType.Transcript,
			createdAt: new Date(),
			content: {
				form: {
					finished: false,
					loadingState: LoadingState.Initial,
				},
			},
		})
	}),
	on(MessageAction.finishTranscript, (state: MessagesState, { payload }) => {
		const messageIndex = state.messages.findIndex(
			message => message.type === 'widget_message' && message.subType === WidgetMessageSubType.Transcript,
		)
		if (messageIndex === -1) {
			console.error("'MessageAction.finishTranscript' no transcript message found")
			return
		}
		// update trancript message
		const transcript = state.messages[messageIndex] as WidgetMessage.Transcript
		const updatedTranscript = cloneDeep(transcript)
		updatedTranscript.content.form.finished = true
		updatedTranscript.content.form.email = payload
		state.messages[messageIndex] = updatedTranscript
		// push new message response to messages feed as a confirmation
		state.messages.push({
			type: MessageType.Message,
			content: {
				type: MessageContentType.Text,
				text: T.translate('emailTranscript.positiveFeedback').replace('$$', payload),
			},
			subType: MessageSubType.System,
			createdAt: new Date().toISOString(),
			agentId: null,
			id: v4(),
			attachments: [],
			channel: {
				id: null,
				type: ChannelType.Default,
			},
			chatId: '',
			groupId: null,
			trigger: null,
			visitorId: '',
		})
	}),
	on(MessageAction.setRatingValue, (state: MessagesState, { payload }) => {
		const { rating, ratingText, messageId } = payload

		let ratingForm = state.ratingForms[messageId]
		if (!ratingForm) {
			const message = state.messages.find(
				message =>
					message.id === messageId &&
					message.type === MessageType.Message &&
					message.content.type === MessageContentType.RateForm,
			) as Message
			if (!message) {
				console.error(
					"'MessageAction.setInputValue' called with wrong message ID, please check the code that called this",
				)
				return
			}

			ratingForm = createRatingForm(message.content.data.value)
		}

		state.ratingForms = {
			...state.ratingForms,
			[messageId]: {
				...ratingForm,
				rating: rating || ratingForm.rating,
				ratingText: ratingText || ratingForm.ratingText,
			},
		}
	}),
	on(MessageAction.updateRatingMessage, (state: MessagesState, { payload }) => {
		const { ratingForm, messageId } = payload

		state.ratingForms = {
			...state.ratingForms,
			[messageId]: {
				...ratingForm,
				rating: ratingForm?.rating,
				ratingText: ratingForm?.ratingText,
				finished: !!ratingForm?.rating,
			},
		}
	}),
	on(MessageAction.submitRatingForm, (state: MessagesState, { payload }) => {
		const selectedMessage = state.messages.find(
			m => m.id === payload && m.type === MessageType.Message && m.content.type === MessageContentType.RateForm,
		) as Message

		if (!selectedMessage) {
			console.error("'MessageAction.submitForm' called with wrong message ID, please check the code that called this")
			return
		}

		const ratingForm = state.ratingForms[payload]
		if (ratingForm) {
			ratingForm.finished = true
			selectedMessage.subType = MessageSubType.Contact
			selectedMessage.content.data = {
				type: MessageContentType.RateForm,
				value: ratingForm.rating,
				text: ratingForm.ratingText,
			}
		}
	}),
	on(MessageAction.addMessageFromServer, (state: MessagesState, { payload }) => {
		const { message } = payload
		const messages = !Array.isArray(message) ? [message] : message
		const mappedMessages = cloneDeep(messages).filter(m => m !== undefined)
		state.messages = [...state.messages, ...mappedMessages]
	}),
	on(MessageAction.setMessagesFromServer, (state: MessagesState, { payload }) => {
		state.messages = [...payload]
	}),
	on(MessageAction.markAllAsRead, (state: MessagesState, _) => {
		const numberMess = state.messages.length

		if (numberMess > 0) {
			visitorClient.client.chatRead()
		}

		state.lastReadAt = new Date().toISOString()
	}),
	on(MessageAction.setEmojiPickerState, (state: MessagesState, { payload }) => {
		state.emojiPickerState = payload
	}),
	on(MessageAction.setWarningBarState, (state: MessagesState, { payload }) => {
		state.warningBarState = payload
	}),
	on(MessageAction.setFileUploadState, (state: MessagesState, { payload }) => {
		state.fileUploadState = payload
	}),
	on(MessageAction.setIsTyping, (state: MessagesState, { payload }) => {
		state.isTyping = payload
	}),
	on(MessageAction.setIsFakeTyping, (state: MessagesState, { payload }) => {
		state.fakeTyping = payload
	}),
	on(MessageAction.addImageMessage, (state: MessagesState, { payload }) => {
		const attachment: Attachment = {
			type: AttachmentType.Image,
			url: payload.url,
			fileName: payload.name,
			fileType: payload.type,
			thumb400: {
				url: payload.url400 || payload.url,
				width: 0,
				height: 0,
			},
		}
		const newImageMessage: Message = {
			id: v4(),
			type: MessageType.Message,
			subType: MessageSubType.Contact,
			createdAt: new Date().toISOString(),
			content: {
				type: MessageContentType.Upload,
				data: attachment,
				text: null,
			},
			channel: {
				id: null,
				type: ChannelType.Default,
			},
			agentId: null,
			attachments: [attachment],
			groupId: null,
			trigger: null,
			chatId: '',
			visitorId: '',
		}
		state.messages.push(newImageMessage)
	}),
	on(MessageAction.addRatingForm, (state: MessagesState, { payload }) => {
		const message = createRatingMessage(payload)
		const ratingForm = createRatingForm(payload.type)
		state.ratingForms[message.id] = ratingForm
		state.messages.push(message)
	}),
	on(MessageAction.removeRatingForm, (state: MessagesState, { payload }) => {
		const { messageId } = payload
		delete state.ratingForms[messageId]
	}),
	on(MessageAction.setFormLoadingState, (state: MessagesState, { payload }) => {
		const { messageId, loadingState } = payload

		const ratingForm = state.ratingForms[messageId]

		if (!ratingForm) {
			console.error(
				"'MessageAction.setFormLoadingState' called with wrong message ID, please check the code that called this",
			)
			return
		}

		ratingForm.loadingState = loadingState
	}),
	on(MessageAction.setInputText, (state: MessagesState, { payload }) => {
		state.inputText = payload
	}),
	on(MessageAction.hideChatbotMessageOptions, (state: MessagesState, { payload }) => {
		const message = state.messages.find(message => message.id === payload && message.subType === MessageSubType.Bot) as
			| Message.Message.Bot
			| undefined
		if (message) {
			message.content.quickReplies = []
		}
	}),
	on(MessageAction.setLastRead, (state: MessagesState, { payload }) => {
		state.lastReadAt = payload
	}),
	on(MessageAction.setMessageSendingState, (state: MessagesState, { payload }) => {
		state.messageSendingState = payload
	}),
	on(MessageAction.setAcceptedFileTypes, (state: MessagesState, { payload }) => {
		state.acceptedFileTypes = payload
	}),
	on(MessageAction.setAcceptedFileExtensions, (state: MessagesState, { payload }) => {
		state.acceptedFileExtensions = payload
	}),
	on(MessageAction.setAcceptedFileMaxSize, (state: MessagesState, { payload }) => {
		state.acceptedFileMaxSize = payload
	}),
	on(MessageAction.setBotName, (state: MessagesState, { payload }) => {
		state.currentBotName = payload
	}),
	on(MessageAction.overrideDynamicWidgetOptions, (state: MessagesState, { payload }) => {
		state.overridingDynamicWidgetOptions = payload || {}
	}),
)
