export const FAKE_BOT_TYPING = 500
export const COOKIE_CHECK_TIMER = 1000
export const DISCONNECT_TIMER = 1000

export const WIDGET_BUTTON_WRAPPER_ANIMATION_TIME = 200
export const WIDGET_BUTTON_WRAPPER_ANIMATION_DELAY = 350
export const WIDGET_BUTTON_TEXT_ANIMATION_DELAY = 400
export const WIDGET_BUTTON_TEXT_ANIMATION_TIME = 280

// Widget button animation
// safespace that adds time to the two animation to make sure, that the width is calculated after the animation is done
const WIDGET_ANIMATION_SAFESPACE_TIMER = 500
const WIDGET_WRAPPER_ANIMATION_TIME_TOTAL =
	WIDGET_BUTTON_WRAPPER_ANIMATION_TIME + WIDGET_BUTTON_WRAPPER_ANIMATION_DELAY + WIDGET_ANIMATION_SAFESPACE_TIMER
const WIDGET_TEXT_ANIMATION_TIME_TOTAL =
	WIDGET_BUTTON_TEXT_ANIMATION_DELAY + WIDGET_BUTTON_TEXT_ANIMATION_TIME + WIDGET_ANIMATION_SAFESPACE_TIMER

export const GET_WIDGET_WIDTH_TIMER = Math.max(WIDGET_WRAPPER_ANIMATION_TIME_TOTAL, WIDGET_TEXT_ANIMATION_TIME_TOTAL)
