export const EmojiConstants: { [key: string]: string } = {
	':)': '🙂',
	':-)': '🙂',
	':D': '😀',
	':-D': '😀',
	';)': '😉',
	';-)': '😉',
	'<3': '❤️',
	':(': '🙁',
	':-(': '🙁',
	':P': '😛',
	':-P': '😛',
}
