import { secretDebug } from './debug'

/**
 * @param message
 * @param err
 */
export const handleError = (message: string, err: Error | any) => {
	secretDebug('message', err)
	console.error(message, err)
}
