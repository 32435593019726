import { storageVisitsName } from 'constants/cookies'
import { getMarketingConsent } from './consents'
import { getFromStorage, setToStorage } from './cookie'

export const getVisitsFromStorage = (): number | undefined => {
	const hasMarketingConsent = getMarketingConsent()
	if (!hasMarketingConsent) return undefined
	return getFromStorage(storageVisitsName) ? Number(getFromStorage(storageVisitsName)) : 0
}

export const setVisitsToStorage = (visits: number): void => {
	const hasMarketingConsent = getMarketingConsent()
	if (hasMarketingConsent) {
		setToStorage({ name: storageVisitsName, value: String(visits) })
	}
}
