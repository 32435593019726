type ConnectQueueFn = () => void

const createConnectQueue = () => {
	const queue: ConnectQueueFn[] = []

	const push = (fn: ConnectQueueFn) => {
		queue.push(fn)
	}

	const executeAll = () => {
		while (queue.length > 0) {
			const fn = queue.shift()
			fn && fn()
		}
	}

	return Object.freeze({ push, executeAll })
}

export const connectQueue = createConnectQueue()
