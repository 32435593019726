import { action, payload, Action } from 'ts-action'
import { Dispatch } from 'redux'
import VisitorClient, {
	AccountStatus,
	Variables,
	WidgetOptions as DynamicWidgetOptions,
} from '@smartsupp/websocket-client-visitor'

import { State } from 'store/combinedReducers'
import { generalSelectors } from 'store/general'
import { checkMessagesAndMarkAsRead, addRating } from 'store/messages/actions'
import { LoadingState, ChatStatus } from 'model/Enums'
import { FormInput } from 'model/FormInput'
import { WindowSize } from 'utils/resizer'
import { saveChatIsOpenToCookie } from 'utils/messageHelpers'
import { visitorClient } from 'utils/connect'
import { getSsWidget } from 'utils/sdk'
import { AppThunkAction } from 'types'
import { WIDGET_OPENING_TIME } from 'constants/animationConstants'
import { GET_WIDGET_WIDTH_TIMER } from 'constants/timeoutConstants'
import { connectQueue } from 'utils/connectQueue'
import { isWidgetConnected } from './selectors'

export const GeneralAction = {
	authFormStateToggle: action('general/AUTH_FORM_STATE_TOGGLE'),
	closeConversationModalToggle: action('general/CLOSE_CONVERSATION_MODAL_TOGGLE'),
	createAuthFormInputs: action('general/CREATE_AUTH_FORM_INPUTS', payload<FormInput[]>()),
	dataPrivacyStateToggle: action('general/DATA_PRIVACY_STATE_TOGGLE'),
	documentFocusToggle: action('general/DOCUMENT_FOCUS_TOGGLE'),
	inputFocusToggle: action('general/INPUT_FOCUS_TOGGLE'),
	setAuthFormInput: action('general/SET_AUTH_FORM_INPUT', payload<{ name: string; value: string | boolean }>()),
	setChatStatus: action('general/SET_CHAT_STATE', payload<ChatStatus>()),
	setRequestState: action('general/SET_REQUEST_STATE', payload<LoadingState>()),
	setSize: action('general/SET_SIZE', payload<WindowSize>()),
	setThemeColor: action('general/SET_THEME_COLOR', payload<string>()),
	setUser: action('general/SET_USER', payload<VisitorClient.ConnectedData>()),
	showMorePopperClose: action('general/SHOW_MORE_POPPER_CLOSE'),
	showMorePopperToggle: action('general/SHOW_MORE_POPPER_TOGGLE'),
	updateUser: action('general/UPDATE_USER', payload<Partial<VisitorClient.Identity>>()),
	updateUserVariables: action('general/UPDATE_USER_VARIABLES', payload<Variables>()),
	updateUserStatus: action('general/UPDATE_USER_STATUS', payload<AccountStatus>()),
	widgetHide: action('general/WIDGET_HIDE'),
	widgetShow: action('general/WIDGET_SHOW'),
	widgetLoadedToggle: action('general/WIDGET_LOADED_TOGGLE'),
	widgetOffline: action('general/WIDGET_OFFLINE'),
	widgetOnline: action('general/WIDGET_ONLINE'),
	widgetOpeningToggle: action('general/WIDGET_OPENING_TOGGLE'),
	widgetClose: action('general/WIDGET_OPEN'),
	widgetOpen: action('general/WIDGET_CLOSE'),
	gaPaused: action('general/GA_PAUSED'),
	gaResumed: action('general/GA_RESUMED'),
	setIsWidgetConnected: action('general/SET_IS_WIDGET_CONNECTED', payload<boolean>()),
	updateDynamicWidgetOptions: action('general/UPDATE_DYNAMIC_WIDGET_OPTIONS', payload<Partial<DynamicWidgetOptions>>()),
}

export const handleApiFunction: AppThunkAction<GeneralAction> =
	(callbackFn: () => void) => (dispatch: Dispatch<Action>, getState: () => State) => {
		const isConnected = isWidgetConnected(getState())
		isConnected ? callbackFn() : connectQueue.push(callbackFn)
	}

export const toggleOpenCloseWidget: AppThunkAction<GeneralAction> =
	(open: boolean, sendNotifyEvent = true) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(GeneralAction.widgetOpeningToggle())
		if (open) {
			dispatch(GeneralAction.widgetOpen())
			if (visitorClient.client && sendNotifyEvent) {
				visitorClient.client.notify(VisitorClient.EventName.WidgetOpen)
			}
		} else {
			dispatch(GeneralAction.widgetClose())
		}
		dispatch(checkMessagesAndMarkAsRead(true))

		// Allows the widget to resize automatically according to text length
		dispatch(GeneralAction.widgetLoadedToggle())
		setTimeout(() => dispatch(GeneralAction.widgetLoadedToggle()), GET_WIDGET_WIDTH_TIMER)

		const saveChatOpenValueIfPossible = () => {
			const allowSaveToCookie = generalSelectors.isWidgetDesktop(getState())
			if (allowSaveToCookie) {
				saveChatIsOpenToCookie(open)
			}
		}

		const isConnected = isWidgetConnected(getState())
		if (isConnected) {
			saveChatOpenValueIfPossible()
		} else {
			connectQueue.push(saveChatOpenValueIfPossible)
		}

		// So that user cannot click the button by accident while opening chat
		setTimeout(() => {
			dispatch(GeneralAction.widgetOpeningToggle())
		}, WIDGET_OPENING_TIME)
	}

export const closeWidgetByCustomer: AppThunkAction<GeneralAction> =
	() =>
	async (dispatch: Dispatch<Action>): Promise<void> => {
		dispatch(GeneralAction.setChatStatus(ChatStatus.ClosedByVisitor))
		visitorClient.client.chatClose()
		if (getSsWidget().options?.ratingEnabled) {
			dispatch(addRating())
		} else {
			dispatch(GeneralAction.widgetClose())
		}
	}

export const showWidget: AppThunkAction<GeneralAction> =
	() =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(GeneralAction.widgetShow())
		if (visitorClient.client && generalSelectors.isWidgetVisible(getState())) {
			visitorClient.client.notify(VisitorClient.EventName.WidgetShow)
		}
	}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GeneralAction = typeof GeneralAction
