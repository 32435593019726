import css from '@emotion/css'
import { MessageSubType } from '@smartsupp/smartsupp-message'
import { MessagePosition } from 'model/Enums'
import { SSPX } from '../constants/themeConstants'
import {
	messageGroupMiddleCss,
	messageGroupStandAloneCss,
	messageGroupTopCss,
	messageGroupBottomCss,
} from '../styles/messages'

export const messageGroupClasses = [
	{
		author: MessageSubType.Agent,
		position: MessagePosition.Standalone,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Agent,
		position: MessagePosition.Top,
		css: css`
			border-radius: ${1.5 * SSPX}px ${1.5 * SSPX}px ${1.5 * SSPX}px 0;
			${{ ...messageGroupTopCss }};
		`,
	},
	{
		author: MessageSubType.Agent,
		position: MessagePosition.Middle,
		css: css`
			border-radius: 0 ${1.5 * SSPX}px ${1.5 * SSPX}px 0;
			${{ ...messageGroupMiddleCss }};
		`,
	},
	{
		author: MessageSubType.Agent,
		position: MessagePosition.Bottom,
		css: css`
			border-radius: 0 ${1.5 * SSPX}px ${1.5 * SSPX}px ${1.5 * SSPX}px;
			${{ ...messageGroupBottomCss }};
		`,
	},
	{
		author: MessageSubType.Contact,
		position: MessagePosition.Standalone,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Contact,
		position: MessagePosition.Top,
		css: css`
			border-radius: ${1.5 * SSPX}px ${1.5 * SSPX}px 0 ${1.5 * SSPX}px;
			${{ ...messageGroupTopCss }};
		`,
	},
	{
		author: MessageSubType.Contact,
		position: MessagePosition.Middle,
		css: css`
			border-radius: ${1.5 * SSPX}px 0 0 ${1.5 * SSPX}px;
			${{ ...messageGroupMiddleCss }};
		`,
	},
	{
		author: MessageSubType.Contact,
		position: MessagePosition.Bottom,
		css: css`
			border-radius: ${1.5 * SSPX}px 0 ${1.5 * SSPX}px ${1.5 * SSPX}px;
			${{ ...messageGroupBottomCss }};
		`,
	},
	{
		author: MessageSubType.System,
		position: MessagePosition.Standalone,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.System,
		position: MessagePosition.Top,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.System,
		position: MessagePosition.Middle,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.System,
		position: MessagePosition.Bottom,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Trigger,
		position: MessagePosition.Standalone,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Trigger,
		position: MessagePosition.Top,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Trigger,
		position: MessagePosition.Middle,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Trigger,
		position: MessagePosition.Bottom,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Bot,
		position: MessagePosition.Standalone,
		css: css`
			${{ ...messageGroupStandAloneCss }};
		`,
	},
	{
		author: MessageSubType.Bot,
		position: MessagePosition.Top,
		css: css`
			border-radius: ${1.5 * SSPX}px ${1.5 * SSPX}px ${1.5 * SSPX}px 0;
			${{ ...messageGroupTopCss }};
		`,
	},
	{
		author: MessageSubType.Bot,
		position: MessagePosition.Middle,
		css: css`
			border-radius: 0 ${1.5 * SSPX}px ${1.5 * SSPX}px 0;
			${{ ...messageGroupMiddleCss }};
		`,
	},
	{
		author: MessageSubType.Bot,
		position: MessagePosition.Bottom,
		css: css`
			border-radius: 0 ${1.5 * SSPX}px ${1.5 * SSPX}px ${1.5 * SSPX}px;
			${{ ...messageGroupBottomCss }};
		`,
	},
]
