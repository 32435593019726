import { Dispatch } from 'redux'
import { action, Action, payload } from 'ts-action'
import { TranslationService } from '../../utils/TranslationService'
import { AppThunkAction } from '../../types'
import { getTranslationsClient, getVisitorLanguage } from './utils'

export const TranslationAction = {
	isFetchingTranslations: action('translations/SET_IS_FETCHING_TRANSLATIONS', payload<boolean>()),
}

export const fetchTranslations: AppThunkAction<TranslationActionType> = async (
	dispatch: Dispatch<Action>,
): Promise<void> => {
	dispatch(TranslationAction.isFetchingTranslations(true))

	const lang = getVisitorLanguage()

	try {
		const translations = await getTranslationsClient().getDefaults(lang)

		TranslationService.setData(translations)
		TranslationService.setCustomTranslationsLanguage(lang)
	} catch (err) {
		console.error('Failed to fetch tranlsations', err)
	}

	dispatch(TranslationAction.isFetchingTranslations(false))
}

type TranslationActionType = typeof TranslationAction
