import { reducer } from 'ts-action'
import { on } from 'ts-action-immer'
import { VariablesAction as Action } from './actions'
import { VisitorCustomVariables } from './types'

export const initialState = {
	userVariables: {} as VisitorCustomVariables,
	pendingVariables: {} as VisitorCustomVariables, // Draft variables
}

export type VariablesState = typeof initialState

export const variablesReducer = reducer<VariablesState>(
	initialState,
	on(Action.resetPendingVariables, state => {
		state.pendingVariables = {}
	}),
	on(Action.setPendingVariables, (state, { payload }) => {
		state.pendingVariables = { ...state.pendingVariables, ...payload }
	}),
	on(Action.updateUserVariables, (state, { payload }) => {
		state.userVariables = { ...state.userVariables, ...payload }
	}),
)
