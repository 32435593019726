import { Component, ErrorInfo } from 'react'

class ErrorBoundary extends Component {
	state = { error: null }

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		this.setState({ error })
		console.error(error)
		// TODO: log error
	}

	render() {
		if (this.state.error) {
			return null
		}

		return this.props.children
	}
}

export default ErrorBoundary
