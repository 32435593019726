import type { CrossDomainStorage } from 'cross-domain-storage/guest'
import { secretDebug } from './debug'
import { getSsWidget } from './sdk'

const getStorageUrl = (): string => {
	const widgetOptions = getSsWidget().options
	if (!widgetOptions.storageUrl) throw new Error('Storage url is missing')

	return `${widgetOptions.storageUrl.replace(/{key}/g, widgetOptions.key)}/index.html`
}

const createCrossDomainStorageProvider = () => {
	let storage: CrossDomainStorage | null = null

	const getStorage = async (): Promise<CrossDomainStorage> => {
		if (storage) return storage

		const createCrossDomainStorage = async () => {
			const { default: createGuest } = await import('cross-domain-storage/guest')
			const storageUrl = getStorageUrl()
			secretDebug('cross domain storage created', storageUrl)
			return createGuest(storageUrl)
		}

		try {
			storage = await createCrossDomainStorage()
			return storage
		} catch {
			throw new Error('Cross domain storage create failed')
		}
	}

	const closeStorage = () => {
		if (storage) {
			storage.close()
			secretDebug('cross domain storage closed')
		}
	}

	return Object.freeze({ getStorage, closeStorage })
}

const crossDomainStorageProvider = createCrossDomainStorageProvider()

export const getFromCrossDomainStorage = async (key: string): Promise<string> => {
	const storage = await crossDomainStorageProvider.getStorage()
	return new Promise((resolve, reject) => {
		storage.get(key, (err, value) => {
			if (err) return reject(err)

			secretDebug('get from cross domain storage', key, value)
			return resolve(value)
		})
	})
}

export const setToCrossDomainStorage = async (key: string, value: string): Promise<void> => {
	const storage = await crossDomainStorageProvider.getStorage()
	return new Promise((resolve, reject) => {
		storage.set(key, value, err => {
			if (err) return reject(err)

			secretDebug('set to cross domain storage', key, value)
			return resolve()
		})
	})
}

export const closeCrossDomainStorage = () => {
	crossDomainStorageProvider.closeStorage()
}
