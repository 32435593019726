import { differenceInSeconds } from 'date-fns'
import { SerializedStyles } from '@emotion/utils'
import { IMessage, Message, MessageContentType, MessageSubType, MessageType } from '@smartsupp/smartsupp-message'
import { FormInput } from 'model/FormInput'
import { SOUND_VOLUME } from 'constants/soundsConstants'
import { setToStorage } from 'utils/cookie'
import { secretDebug } from 'utils/debug'
import { ASSETS_BASE_URL } from 'constants/apiConstants'
import { storageOpenName, storageTextName } from 'constants/cookies'
import { EmojiConstants } from 'constants/smileyConstants'
import { messageGroupClasses } from 'data-sets/messagesGroupClasses'
import { personalDataProcessingConsentName } from 'utils/authFormHelper'
import { MessagePosition } from 'model/Enums'
import { FeedMessage } from 'store/messages/types'

const EMOJI_REGEX = new RegExp(
	Object.keys(EmojiConstants)
		.map(key => `${key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}($| )`) // escape regex function
		.join('|'),
	'gi',
)

export const playSound = (messageSubtype: string, messageType: string): void => {
	if (
		messageSubtype !== MessageSubType.Contact &&
		messageType !== MessageContentType.RateForm &&
		messageType !== MessageContentType.AgentJoin &&
		messageType !== MessageContentType.AgentLeave &&
		messageType !== MessageContentType.ChatTransfer
	) {
		const audio = new Audio(`${ASSETS_BASE_URL}/assets/sounds/blackberry2.mp3`)
		audio.crossOrigin = 'anonymous'
		audio.load()
		audio.volume = SOUND_VOLUME

		audio.addEventListener('canplaythrough', async () => {
			try {
				await audio.play()
			} catch (error) {
				secretDebug('Could not play sound.', error)
			}
		})
	}
}

export interface MessageIdWithPosition {
	id: string
	position: MessagePosition
}
const defaultMaxDiffTimeInSeconds = 60

export const shouldBeInGroupWith = (firstMessage: FeedMessage, secondMessage: FeedMessage, maxDiff: number) =>
	Math.abs(differenceInSeconds(new Date(firstMessage.createdAt), new Date(secondMessage.createdAt))) < maxDiff &&
	firstMessage.subType === secondMessage.subType

export const sortMessagesByDate = (messages: FeedMessage[]) => {
	const newMessages = [...messages]
	return newMessages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
}

export const groupMessages = (
	messages: FeedMessage[],
	maxDiff: number = defaultMaxDiffTimeInSeconds,
): MessageIdWithPosition[] => {
	// mark all of the messages as standalone
	const result: MessageIdWithPosition[] = messages.map(m => ({
		id: m.id,
		position: MessagePosition.Standalone,
	}))

	// if just one message return because that message is standalone
	if (messages.length <= 1) {
		return result
	}

	// fix the first one (top or standalone)
	if (shouldBeInGroupWith(messages[0], messages[1], maxDiff)) {
		result[0].position = MessagePosition.Top
	}

	// fix the last one (bottom or standalone)
	if (shouldBeInGroupWith(messages[messages.length - 2], messages[messages.length - 1], maxDiff)) {
		result[messages.length - 1].position = MessagePosition.Bottom
	}

	// fix the one in the middle
	for (let i = 1; i < messages.length - 1; i += 1) {
		const inGroupWithUpper = shouldBeInGroupWith(messages[i - 1], messages[i], maxDiff)
		const inGroupWithDown = shouldBeInGroupWith(messages[i], messages[i + 1], maxDiff)

		if (inGroupWithDown && inGroupWithUpper) {
			result[i].position = MessagePosition.Middle
		}

		if (inGroupWithDown && !inGroupWithUpper) {
			result[i].position = MessagePosition.Top
		}

		if (!inGroupWithDown && inGroupWithUpper) {
			result[i].position = MessagePosition.Bottom
		}
	}
	return result
}

export const lastTextMessageSent = (smartMessages: Message[]) => {
	const textMessages = smartMessages
		.filter(m => m.subType === MessageSubType.Contact && m.content.type === MessageContentType.Text)
		.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

	return textMessages[textMessages.length - 1]
}

export const filterLatestTriggerMessages = (messages: FeedMessage[]) =>
	messages
		.filter(
			message =>
				message.type === MessageType.Message &&
				(message.subType === MessageSubType.Trigger || message.content.type === MessageContentType.Text) &&
				message.subType !== MessageSubType.Contact,
		)
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

export const nonSystemMessages = (messages: Message[]): Message[] =>
	messages.filter(m => m.subType !== MessageSubType.System)

export const filterUserAgentMessages = (messages: FeedMessage[]) =>
	messages.filter(
		m => m.type === MessageType.Message && (m.subType === MessageSubType.Agent || m.subType === MessageSubType.Contact),
	)

export const replaceEmojisInText = (text: string): string =>
	text.replace(EMOJI_REGEX, matched => {
		const selected = Object.keys(EmojiConstants).filter(k => matched.toUpperCase().includes(k))[0]
		return EmojiConstants[selected].toString()
	})

export const saveChatIsOpenToCookie = (isOpen: boolean) => {
	setToStorage({ name: storageOpenName, value: `${isOpen}`, excludeCookie: true })
}

export const saveChatInputTextToCookie = (text: string) => {
	// Prevents input text from saving to localStorage, only to cookie
	setToStorage({ name: storageTextName, value: text, excludeCookie: true })
}

interface ValuesFromForm {
	name?: string
	email?: string
	phone?: string
	group?: string
	personalDataProcessingConsent?: boolean
}

export const getValuesFromForm = (inputs: FormInput[]): ValuesFromForm => {
	const name = inputs.find(i => i.name === 'name')
	const email = inputs.find(i => i.name === 'email')
	const phone = inputs.find(i => i.name === 'phone')
	const group = inputs.find(i => i.name === 'group')
	const personalDataProcessingConsent = inputs.find(i => i.name === personalDataProcessingConsentName)

	return {
		name: name ? name.value.toString() : undefined,
		email: email ? email.value.toString() : undefined,
		phone: phone ? phone.value.toString() : undefined,
		group: group ? group.value.toString() : undefined,
		personalDataProcessingConsent: personalDataProcessingConsent ? !!personalDataProcessingConsent.value : undefined,
	}
}

export const lastTextMessage = (messages: FeedMessage[]) => {
	if (messages.length) {
		const sortedMessages = sortMessagesByDate(messages)
		const textMessages = sortedMessages.filter(
			m => m.type === MessageType.Message && m.content.type === MessageContentType.Text && m.content.text,
		)
		if (textMessages.length) {
			return textMessages[textMessages.length - 1]
		}
	}
	return undefined
}

export const userSentSameMessageTwiceInRow = (messages: FeedMessage[], text: string) => {
	const lastMessage = lastTextMessage(messages)
	if (
		lastMessage &&
		lastMessage.type === MessageType.Message &&
		text === lastMessage.content.text &&
		lastMessage.subType === MessageSubType.Contact
	) {
		return true
	}
	return false
}

export const getMessageCss = (author: string, position: string): SerializedStyles =>
	messageGroupClasses.find(m => m.author === author && m.position === position)!.css

export function resolveAgentId(message: IMessage): string | null {
	if (
		message.content.type === MessageContentType.AgentJoin ||
		message.content.type === MessageContentType.AgentLeave ||
		message.content.type === MessageContentType.ChatClose
	) {
		return message.content.data.agentId
	}

	if (message.content.type === MessageContentType.AgentAssign) {
		return message.content.data.assigned
	}

	if (message.content.type === MessageContentType.AgentUnassign) {
		return message.content.data.unassigned
	}

	return null
}

export function resolveAuthor(message: IMessage): MessageSubType {
	if (
		message.content.type === MessageContentType.Upload ||
		message.subType === MessageSubType.Contact ||
		message.subType === MessageSubType.Agent
	) {
		return message.subType === MessageSubType.Contact ? MessageSubType.Contact : MessageSubType.Agent
	}

	const systemContentType: string[] = [
		MessageContentType.AgentLeave,
		MessageContentType.AgentAssign,
		MessageContentType.AgentJoin,
		MessageContentType.AgentUnassign,
		MessageContentType.ChatClose,
		MessageContentType.ChatVisitorClose,
		MessageContentType.RateForm,
		MessageContentType.ChatTransfer,
	]
	if (systemContentType.includes(message.content.type)) {
		return MessageSubType.System
	}

	return message.subType
}
