import { storageIdName } from 'constants/cookies'
import { getSsWidget } from 'utils'
import { getFromStorage, setToStorage } from './cookie'
import { getFromCrossDomainStorage, setToCrossDomainStorage } from './crossDomainStorage'

const isCrossDomainEnabled = (): boolean => {
	const widgetOptions = getSsWidget().options
	return !!widgetOptions?.crossDomainEnabled
}

export const getVisitorIdFromStorage = async (): Promise<string | null> => {
	if (isCrossDomainEnabled()) {
		return getFromCrossDomainStorage(storageIdName)
	}
	return getFromStorage(storageIdName) || null
}

export const setVisitorIdToStorage = async (visitorId: string): Promise<void> => {
	if (isCrossDomainEnabled()) {
		return setToCrossDomainStorage(storageIdName, String(visitorId))
	}
	return setToStorage({ name: storageIdName, value: String(visitorId) })
}
