import { Dispatch } from 'react'
import { action, payload, Action } from 'ts-action'
import VisitorClient, { AccountStatus, VisitorEvents, Agent } from '@smartsupp/websocket-client-visitor'

import { State } from 'store/combinedReducers'
import { generalSelectors } from 'store/general'
import { AgentRating } from 'model/Enums'
import { visitorClient } from 'utils/connect'
import { getSsWidget } from 'utils/sdk'
import { AppThunkAction } from 'types'

export const AgentActions = {
	setRating: action('agent/SET_RATING', payload<AgentRating>()),
	setAgents: action('agent/SET_AGENT', payload<Agent[]>()),
	updateAgentStatus: action('agent/UPDATE_AGENT_STATUS', payload<VisitorEvents.AgentStatusUpdated>()),
	updateAgent: action('agent/UPDATE_AGENT', payload<VisitorEvents.AgentUpdated>()),
	setConnectedAgents: action('agent/SET_CONNECTED_AGENTS', payload<Agent[]>()),
	setIsAgentTyping: action('agent/SET_IS_AGENT_TYPING', payload<boolean>()),
	setStatus: action('agent/SET_STATUS', payload<AccountStatus>()),
	addConnectedAgent: action('agent/ADD_AGENT', payload<Agent>()),
	addConnectedAgentFromTransfer: action('agent/ADD_AGENT_FROM_TRANSFER', payload<VisitorEvents.ChatAgentAssigned>()),
	removeConnectedAgentFromTransfer: action(
		'agent/REMOVE_AGENT_FROM_TRANSFER',
		payload<VisitorEvents.ChatAgentUnassigned>(),
	),
	removeConnectedAgent: action('agent/REMOVE_AGENT_FROM_CONNECTED', payload<string>()),
	deleteAgent: action('agent/DELETE_AGENT', payload<string>()),
	removeAllConnectedAgents: action('agent/REMOVE__ALL_AGENTS'),
}

export const setAgentStatus: AppThunkAction<AgentActions> =
	(chatState: AccountStatus) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(AgentActions.setStatus(chatState))
		if (generalSelectors.isWidgetVisible(getState()) && getSsWidget().options?.hideOfflineChat) {
			visitorClient.client.notify(VisitorClient.EventName.WidgetShow)
		}
	}

export const setAgentStatusWithNotification: AppThunkAction<AgentActions> =
	(chatState: AccountStatus) =>
	async (dispatch: Dispatch<Action>, getState: () => State): Promise<void> => {
		dispatch(AgentActions.setStatus(chatState))

		if (
			generalSelectors.isWidgetVisible(getState()) &&
			getSsWidget().options?.hideOfflineChat &&
			chatState === AccountStatus.Online
		) {
			visitorClient.client.notify(VisitorClient.EventName.WidgetShow)
		}
	}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AgentActions = typeof AgentActions
