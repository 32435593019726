import { keyframes, css } from '@emotion/core'

import { INPUT_BORDER_INDEX } from 'constants/zIndexConstants'

export const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
`

export const waveAnimation = keyframes`
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-9px);
	}
`

export const scaleUpAnimation = keyframes`
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
}
`

export const appearFromBottomGradualAnimation = keyframes`
  0% {
    opacity: 0.8;
    transform: translateY(72px);
    z-index: ${INPUT_BORDER_INDEX};
    overflow: hidden !important;
  }
  99% {
    overflow: hidden !important;
  }
  100% {
    opacity: 1;
    overflow: hidden !important;
    transform: translateY(0px);
    z-index: ${INPUT_BORDER_INDEX};
  }
`

export const appearFromBottomAnimation = keyframes`
  0% {
    opacity: 0.8;
    transform: translateY(6px);
    transition: all 250ms;
  }
  100% {
    opacity: 1;
    transition: all 250ms;
    transform: translateY(0px);
  }
`

export const appearFromBottomCss = css`
	animation: ${appearFromBottomAnimation} 0.25s ease-out both 0ms;
	transition: all 50ms;
`
