import { MessageContentType, MessageSubType, MessageType } from '@smartsupp/smartsupp-message'
import { FeedMessage } from './types'

export const shouldMessageBeUnread = (message: FeedMessage): boolean =>
	message.type === MessageType.Message &&
	(message.subType === MessageSubType.Agent ||
		message.subType === MessageSubType.Bot ||
		message.subType === MessageSubType.Trigger ||
		message.content.type === MessageContentType.RateForm)

export const getUnreadMessages = (messages: FeedMessage[], lastReadTime: string | null): FeedMessage[] =>
	lastReadTime
		? messages.filter(message => new Date(message.createdAt) > new Date(lastReadTime) && shouldMessageBeUnread(message))
		: messages.filter(message => shouldMessageBeUnread(message))
